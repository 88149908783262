<div class="grid-outer" ngClass="{{ passGridName }}">
  <p-treeTable
    #treetable
    [value]="paginatorGridDatas"
    [columns]="headerColumns"
    [rows]="rows"
    [scrollable]="true"
    [totalRecords]="totalRecords"
    [(selection)]="selected"
    selectionMode="multiple"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        @for (col of columns; track col) {
        <th id="table-head">
          {{ col.header }}
        </th>
        }
      </tr>
      <tr *ngIf="hideFilterAndSorting">
        @for (col of columns; track $index) {
        <th id="table-sort-filter">
          <div class="filter-data">
            <span
              [ttSortableColumn]="col.field"
              class="sort-icon"
              *ngIf="hideSorting"
              ><p-treeTableSortIcon [field]="col.field"
            /></span>
            <button
              class="filter-icon pi pi-filter"
              (click)="applyFilter($event, $index)"
              *ngIf="hideFilter"
            ></button>
            @if (filterIndex == $index && filter) {
            <div class="filter-input-outer" *ngIf="hideFilter">
              <input
                pInputText
                type="text"
                class="input-box"
                (input)="applyFilterGlobal($event, 'contains')"
                placeholder="Filter"
              />
            </div>
            }
          </div>
        </th>
        }
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-rowNode
      let-rowData="rowData"
      let-columns="columns"
    >
      <tr [ttRow]="rowNode">
        @for (col of columns; track $index) {
        <td>
          <div class="table-data-inner">
            @if($index == 0){
            <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
            } @if($index === statusIndex && isRequiredStatus){
            <span
              class="completion-status"
              [ngClass]="{
                complete: convertStrToNum(rowData[col.field]) === 100,
                excess: convertStrToNum(rowData[col.field]) > 100,
                'in-progress':
                  convertStrToNum(rowData[col.field]) > 0 &&
                  convertStrToNum(rowData[col.field]) < 100
              }"
              [ngStyle]="{
                '--percentage': convertStrToNum(rowData[col.field])
              }"
            >
              <ng-container *ngIf="convertStrToNum(rowData[col.field]) === 100">
                <i class="icon"></i>
              </ng-container>
              <ng-container *ngIf="convertStrToNum(rowData[col.field]) > 100">
                <i class="icon"></i>
              </ng-container>
              <ng-container
                *ngIf="
                  convertStrToNum(rowData[col.field]) >= 0 &&
                  convertStrToNum(rowData[col.field]) < 100
                "
              >
                <i class="icon"></i>
              </ng-container>
            </span>
            }
            <a
              (click)="onNodeClick($event, $index)"
              [ngClass]="getClassNameforData(rowData[col.field], $index)"
              >{{ rowData[col.field] }}</a
            >
          </div>
        </td>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <div *ngIf="gridData.length == 0 || !gridData">
        <div class="data-not-found">Data Not Found</div>
      </div>
    </ng-template>
  </p-treeTable>
</div>
