import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { PaginatorModule } from 'primeng/paginator';
@Component({
  selector: 'rcp-paginator',
  standalone: true,
  imports: [PaginatorModule],
  templateUrl: './paginator.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrl: './paginator.component.scss',
})
export class PaginatorComponent {
  @Input() first: number;
  @Input() rows: any;
  @Input() totalRecords: number;
  @Output() onPageChangeEvent = new EventEmitter<any>();
  perPageRecodText = 'per page';
  constructor(private elementRef: ElementRef) {}
  // This function is used for paginator value changes
  changeValueForPaginator(start: number) {
    this.first = start;
  }
  // Using below function we can emit the paginator data
  onPageChange(event: any) {
    this.onPageChangeEvent.emit(event);
  }
  // This function is used for modifying the paginator UI
  ngAfterViewInit() {
    const paginatorCurrent = this.elementRef.nativeElement.querySelector(
      '.p-paginator-current'
    );
    paginatorCurrent.insertAdjacentHTML(
      'afterbegin',
      '<span class="per-page-text">' + this.perPageRecodText + '</span>'
    );
    const d1 = this.elementRef.nativeElement.querySelector(
      'p-dropdown[styleclass="p-paginator-rpp-options"]'
    );
    d1.setAttribute('editable', 'true');
  }
}
