import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable()
export class NodeService {
  URL = 'assets/filesystem.json';
  URL2='https://radial-st1.bpost.cloud/api/radial/listingview';
  constructor(private http: HttpClient) {}
  getFilesystem(): Observable<any> {
    return this.http.get<any>(this.URL);
  }
  getReturnList(){
    return this.http.get<any>(this.URL2);
  }
}
