// Import PrimeNG modules
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';
import { NodeService } from '../../services/nodeservice';
@NgModule({
  imports: [
    TreeTableModule,
    HttpClientModule,
    TableModule,
    InputTextModule,
    ButtonModule,
    FormsModule,
    PaginatorModule,
  ],
  exports: [
    TreeTableModule,
    HttpClientModule,
    InputTextModule,
    ButtonModule,
    PaginatorModule,
  ],
  providers: [NodeService],
})
export class ImportsModule {}
